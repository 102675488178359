import cc from 'classcat';
import PropTypes from 'prop-types';

import css from './CheckBox.module.scss';

const CheckBox = ({ centered, checked, label, name, onChange, required, value }) => (
  <div className={cc([css.root, { [css.centered]: centered }])}>
    <label className={css.label} htmlFor={`checkbox-${name}`}>
      <input
        className={css.input}
        checked={checked}
        type="checkbox"
        id={`checkbox-${name}`}
        name={name}
        onChange={() => {
          onChange({ target: { name, value, label } });
        }}
        required={required}
      />
      <span className={css.text} dangerouslySetInnerHTML={{ __html: label }} />
    </label>
  </div>
);
CheckBox.defaultProps = {
  centered: false,
  checked: false,
  required: false,
};
CheckBox.propTypes = {
  centered: PropTypes.bool,
  checked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number, PropTypes.shape]).isRequired,
};

export default CheckBox;
