/* eslint-disable react/no-array-index-key */
import { memo, useState } from 'react';
import PropTypes from 'prop-types';

import stripHtmlTags from 'events/utils/strings/stripHtmlTags';
import truncate from 'events/utils/strings/truncate';
import Button from '../Button';

import css from './index.module.scss';

const Description = ({ text }) => {
  const [expanded, setExpanded] = useState(false);
  if (expanded || text.length < 151) {
    return <div className={css.description} dangerouslySetInnerHTML={{ __html: text }} />;
  }
  return (
    <div className={css.desktop}>
      <div className={css.description} dangerouslySetInnerHTML={{ __html: truncate(stripHtmlTags(text), 150) }} />

      <Button
        linkStyle
        onClick={() => {
          setExpanded(true);
        }}
      >
        See More
      </Button>
    </div>
  );
};
Description.propTypes = {
  text: PropTypes.string.isRequired,
};

export default memo(Description);
