/* eslint-disable camelcase */

import moment from 'moment';

import { RSVP_TYPES } from 'events/config';

export const dateToStringShort = ({ date }) => moment(date, 'YYYY-MM-DD').format('dddd, MMMM Do');

export const timeToString = ({ date, timeStart, timeEnd, isAllDay, isTimeEndSuppressed }) => {
  if (isAllDay) {
    return '';
  }

  const startDate = moment(`${date} ${timeStart}`);
  const formattedStartTime = startDate.format('h:mm A');

  if (isTimeEndSuppressed) {
    return formattedStartTime;
  }

  const endDate = moment(`${date} ${timeEnd}`);
  return `${formattedStartTime} — ${endDate.format('h:mm A')}`;
};

export const dateToStringLong = ({ date, timeStart, timeEnd, isAllDay, isTimeEndSuppressed }) => {
  const formattedDate = dateToStringShort({ date });

  if (isAllDay) {
    return formattedDate;
  }

  const formattedTime = timeToString({
    date,
    timeStart,
    timeEnd,
    isAllDay,
    isTimeEndSuppressed,
  });
  return `${formattedDate} ${formattedTime}`;
};

export const getNewDate = () => ({
  node: {
    id: `_new_${new Date().getTime()}`,
    date: '',
    timeStart: '',
    timeEnd: '',
    registrationUrl: '',
    rsvpMax: 1,
    rsvpType: RSVP_TYPES.UNLIMITED,
  },
});
