/* eslint-disable no-plusplus */
/**
 * Remove chars from end of string.
 */
import WHITE_SPACES from './whitespaces';

const rtrim = (str, chars = WHITE_SPACES) => {
  let end = str.length - 1;
  const charLen = chars.length;
  let found = true;
  let i;
  let c;

  while (found && end >= 0) {
    found = false;
    i = -1;
    c = str.charAt(end);

    while (++i < charLen) {
      if (c === chars[i]) {
        found = true;
        end--;
        break;
      }
    }
  }

  return end >= 0 ? str.substring(0, end + 1) : '';
};
export default rtrim;
