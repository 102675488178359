/* eslint-disable react/no-array-index-key */
import cc from 'classcat';
import { memo } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'events/routes';
import { dateToStringShort, timeToString } from 'events/utils/dates';
import Image from '../Image';
import Button from '../Button';

import css from './index.module.scss';
import Description from './Description';

const Item = ({ eventDate }) => {
  const { event, locations, rsvp, url } = eventDate;
  const { category, displayLocation, name, description, imageTreatment } = event;
  const image = event.image ? event.image : category.image;
  const location = locations.length > 0 ? locations[0] : event.locations[0];
  const sizes = [{ size: 100, minWidth: 0 }];
  return (
    <>
      <Link route={url}>
        <a className={css.link} href={url}>
          <div className={css.linkImgWrap}>
            <Image className={css.linkImg} {...image} sizes={sizes} treatment={imageTreatment} />
          </div>
        </a>
      </Link>
      <div className={css.addressDescriptionDetails}>
        <div className={css.descriptionDetails}>
          {!!category && <span className={css.categoryName}>{category.name}</span>}
          <Link route={url}>
            <a className={cc([css.link, css.linkTitle])}>{name}</a>
          </Link>
          {description && <Description text={description} />}
        </div>
        <div className={css.addressDetails}>
          <div className={css.detailsContainer}>
            <div className={css.timeLocationContainer}>
              <div className={css.time}>
                <span>{dateToStringShort(eventDate)}</span>
                <span className={css.desktop}>
                  {timeToString(eventDate)}
                  {!displayLocation && ' CDT'}
                </span>
              </div>
              <div className={css.location}>
                <div className={css.locationName}>
                  <div className={css.mobile}>
                    {timeToString(eventDate)}
                    {!displayLocation && ' CDT'}
                  </div>
                  {displayLocation && <span>{location.name}</span>}
                </div>
                {displayLocation && (
                  <div className={css.address}>{`${location.address1} ${location.city}, ${location.state}`}</div>
                )}
              </div>
            </div>
            <Button className={css.detailsBtn} bordered={!rsvp} route={url} big>
              {rsvp && (
                <>
                  <span className={css.desktop}>Cancel RSVP</span>
                  <span className={css.mobile}>Cancel</span>
                </>
              )}
              {!rsvp && 'Details'}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
Item.propTypes = {
  eventDate: PropTypes.shape({
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        address1: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
      }),
    ),
    event: PropTypes.shape({
      description: PropTypes.string,
      displayLocation: PropTypes.bool.isRequired,
      imageTreatment: PropTypes.string,
      name: PropTypes.string,
      category: PropTypes.shape({
        image: PropTypes.shape(),
        name: PropTypes.string,
      }),
      image: PropTypes.shape(),
      locations: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          address1: PropTypes.string,
          city: PropTypes.string,
          state: PropTypes.string,
        }),
      ),
      url: PropTypes.string,
    }),
    rsvp: PropTypes.bool,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(Item);
