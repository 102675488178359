import cc from 'classcat';
import PropTypes from 'prop-types';
import React from 'react';

import CheckBox from 'events/components/CheckBox';

import css from './FiltersMobile.module.scss';

const CheckList = ({ label, onChange, options, value }) => (
  <div className={css.options}>
    {options.map((option) => (
      <div className={css.option} key={`${label}-${option.label}`}>
        <CheckBox
          {...option}
          name={`${label.split(' ').join('-')}-${option.label.split(' ').join('-')}`}
          onChange={onChange}
          checked={option.value === value}
        />
      </div>
    ))}
  </div>
);
CheckList.defaultProps = {
  value: '',
};
CheckList.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  value: PropTypes.string,
};

const FiltersMobile = ({ filters, filterSelect, on }) => (
  <ul className={cc([css.list, { [css.on]: on }])}>
    {filters.map((filter) => (
      <li className={css.item} key={filter.label}>
        <div className={css.title}>{filter.label}</div>
        <CheckList
          label={filter.label}
          placeholder={filter.label}
          query={filter.query}
          onChange={({ target }) => {
            filterSelect(filter, target);
          }}
          name={filter.label.split(' ').join('-')}
          options={filter.options}
          variables={filter.variables}
          value={filter.selected ? filter.selected.value : ''}
        />
      </li>
    ))}
  </ul>
);

FiltersMobile.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  filterSelect: PropTypes.func.isRequired,
  on: PropTypes.bool.isRequired,
};

export default FiltersMobile;
