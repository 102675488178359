import PropTypes from 'prop-types';
import React from 'react';

import css from './index.module.scss';

const NoEvents = ({ title, copy }) => (
  <>
    <h1 className={css.title}>{title}</h1>
    <p className={css.copy}>{copy}</p>
  </>
);

NoEvents.propTypes = {
  copy: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default NoEvents;
