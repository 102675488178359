/* eslint-disable no-plusplus */
/**
 * Remove chars from beginning of string.
 */
import WHITE_SPACES from './whitespaces';

const ltrim = (str, chars = WHITE_SPACES) => {
  let start = 0;
  const len = str.length;
  const charLen = chars.length;
  let found = true;
  let i;
  let c;

  while (found && start < len) {
    found = false;
    i = -1;
    c = str.charAt(start);

    while (++i < charLen) {
      if (c === chars[i]) {
        found = true;
        start++;
        break;
      }
    }
  }

  return start >= len ? '' : str.substr(start, len);
};
export default ltrim;
