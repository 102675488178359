/*
  Take an object and return it as a key/valu string for use with Posts to Django.

  e.x.

    import objToSearcparams from './utils/objToParams';

    const params = objToSearcparams({ foo: 'bar', boo: 'baz' }); // 'foo=bar&boo=baz'
*/
const objToParams = (body) =>
  Object.keys(body)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(body[key])}`)
    .join('&');

export default objToParams;
