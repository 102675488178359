import cc from 'classcat';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import smoothScroll from 'events/utils/dom/smoothScroll';
import ArrowDown from '../icons/ArrowDown';
import Image from '../Image';

import css from './index.module.scss';

const Hero = ({ image, market }) => {
  const rootRef = useRef(null);
  const onClickScroll = () => {
    if (!rootRef.current) return;
    const heroHeight = rootRef.current.offsetHeight;
    smoothScroll(heroHeight);
  };

  const subtitle = market ? market.name : 'Move your body with Outdoor Voices';

  return (
    <div className={css.root} ref={rootRef}>
      <h1 className={css.title}>#DoingThings</h1>
      <h2 className={css.subtitle}>{subtitle}</h2>
      {image && (
        <Image
          {...image}
          className={css.image}
          sizes={[
            { size: 1200, minWidth: 801 },
            { size: 800, minWidth: 541 },
            { size: 540, minWidth: 0 },
          ]}
          width={2400}
        />
      )}
      {image && (
        <Image
          {...image}
          src={image.mobile}
          className={cc(css.imageMobile)}
          sizes={[
            { size: 800, minWidth: 501 },
            { size: 500, minWidth: 275 },
            { size: 274, minWidth: 0 },
          ]}
          width={1000}
        />
      )}
      <div className={css.buttons}>
        <button
          aria-label="Click To Scroll Page To Upcoming Events"
          className={css.jumper}
          onClick={onClickScroll}
          type="button"
        >
          <span>Join us</span>
          <ArrowDown />
        </button>
      </div>
    </div>
  );
};

Hero.defaultProps = {
  image: null,
  market: false,
};

Hero.propTypes = {
  image: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape()]),
  market: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape()]),
};

export default Hero;
