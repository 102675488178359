/* eslint-disable max-len, react/prop-types */
const Icon = ({ className = '' }) => (
  <svg className={className} width="36" height="36" viewBox="0 0 36 36">
    <title>Down</title>
    <path
      d="M19 23.586l5.364-5.364 1.414 1.414L18 27.414 16.586 26l-6.364-6.364 1.414-1.414L17 23.586V10h2v13.586z"
      fillRule="evenodd"
    />
  </svg>
);
export default Icon;
