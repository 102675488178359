/* eslint-disable react/no-array-index-key */
import { memo } from 'react';
import PropTypes from 'prop-types';

import css from './index.module.scss';
import Item from './Item';

const List = ({ items, title }) => (
  <section className={css.root}>
    <h3 className={css.title}>{title}</h3>
    <ul className={css.list}>
      {items &&
        items.length > 0 &&
        items.map((item) => (
          <li className={css.listItem} key={item.id}>
            <Item eventDate={item} />
          </li>
        ))}
    </ul>
  </section>
);
List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  title: PropTypes.string.isRequired,
};
export default memo(List);
