import cc from 'classcat';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Button from '../Button';
import ChevronDown from '../icons/ChevronDown';
import css from './Filters.module.scss';
import FiltersMobile from './FiltersMobile';

const Filters = ({ clearFilters, filtered, filters, filterSelect }) => {
  const [filtering, setFiltering] = useState(false);
  const toggleFilters = () => {
    setFiltering(!filtering);
  };

  const onClearAll = () => {
    setFiltering(false);
    clearFilters();
  };

  const filterIcon = <ChevronDown className={cc([css.icon, { [css.flip]: filtering }])} />;
  return (
    <div className={css.root}>
      <div className={css.inner}>
        <ul className={css.list}>
          <li className={cc([css.item, css.mobile])}>
            <Button small linkStyle onClick={toggleFilters} iconRight={filterIcon}>
              {filters[0].selected ? filters[0].selected.label : 'Filter'}
            </Button>
          </li>
          {filtered && (
            <li className={cc([css.item, css.bordered])}>
              <Button small linkStyle onClick={onClearAll}>
                Clear All
              </Button>
            </li>
          )}
        </ul>
      </div>
      <div className={cc([css.dropdown, { [css.on]: filtering }])}>
        <div className={css.dropdownInner}>
          <FiltersMobile
            on={filtering}
            filters={filters}
            filterSelect={(filter, value) => {
              setFiltering(false);
              filterSelect(filter, value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

Filters.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  filtered: PropTypes.bool.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  filterSelect: PropTypes.func.isRequired,
};

export default Filters;
