import cc from 'classcat';
import Head from 'next/head';
import moment from 'moment';
import PropTypes from 'prop-types';

import { APP_ID, BANNER_IMAGE, PAGE_TITLE, RSVP_STATUSES, SUBSCRIBE_IMAGE } from '../config';
import { initializeApollo } from '../utils/graphql/useApollo';
import { MARKETS_QUERY, GET_ALL_MARKETS_QUERY } from '../queries/market';
import { ME_QUERY } from '../queries/account';
import { MY_RSVPS_QUERY } from '../queries/rsvps';
import { Router } from '../routes';
import { serverSideAuthNoRedirect } from '../utils/auth/serversideTokens';
import css from './index.module.scss';
import Filters from '../components/Filters';
import Hero from '../components/Hero';
import Layout from '../components/Layout';
import List from '../components/List';
import NoEvents from '../components/NoEvents';
import SubscribeForm from '../components/SubscribeForm';

const MY_EVENTS = 'my-events';
const MY_EVENTS_EMPTY_COPY = {
  title: "You're Wide Open!",
  copy: 'Looks like you haven’t RSVP’d to any events yet. Find events near you and start #DoingThings.',
};
const EMPTY_COPY = {
  title: 'No events at the moment',
  copy: 'Sign up to our newsletter to be notified of future events.',
};

const rsvped = (rsvps, id) =>
  rsvps[id] !== undefined && (rsvps[id] === RSVP_STATUSES.CONFIRMED || rsvps[id] === RSVP_STATUSES.WAITLISTED);

const Markets = ({ dates, markets, options, rsvpMap, me, slug, token }) => {
  const filterOptions = token ? [{ label: 'My Events', value: MY_EVENTS }, ...options] : options;
  const filter = {
    label: 'All Events',
    options: filterOptions,
    selected: filterOptions.find((opt) => opt.value === slug),
  };

  const market = slug ? markets.find((m) => m.slug === slug) || false : false;
  const title = market ? `#DoingThings in ${market.shortName} ${PAGE_TITLE}` : '#DoingThings :: Outdoor Voices';
  const description = market
    ? `Outdoor Voices Community Events in ${market.name}`
    : 'Outdoor Voices Community Events - Moving your body and having fun with friends';
  const url = market ? `https://events.outdoorvoices.com/${market.slug}/` : 'https://events.outdoorvoices.com/';

  let events = dates;
  if (slug === MY_EVENTS) {
    events = dates.filter((date) => rsvped(rsvpMap, date.id));
  } else if (slug) {
    events = dates.filter(({ event }) => event.market.isOnline || event.market.slug === slug);
  }

  const linksThisWeek = [];
  const linksNextWeek = [];
  const linksComingSoon = [];

  const eow = moment().endOf('week');
  const eonw = moment().endOf('week').add(7, 'days');

  events.forEach((dat) => {
    const { date } = dat;
    const dateOfEvent = moment(date);
    const evt = {
      ...dat,
      rsvp: rsvped(rsvpMap, dat.id),
    };
    if (dateOfEvent < eow) {
      linksThisWeek.push(evt);
    } else if (dateOfEvent < eonw) {
      linksNextWeek.push(evt);
    } else {
      linksComingSoon.push(evt);
    }
  });

  const hasThisWeek = linksThisWeek.length > 0;
  const hasNextWeek = linksNextWeek.length > 0;
  const hasComingSoon = linksComingSoon.length > 0;
  const any = hasThisWeek || hasNextWeek || hasComingSoon;

  const onFilterClear = () => {
    Router.pushRoute('/');
  };
  const onFilterSelect = (_filter, { value }) => {
    Router.pushRoute(slug === value ? '/' : `/${value}`);
  };
  const emptyCopy = slug === MY_EVENTS ? MY_EVENTS_EMPTY_COPY : EMPTY_COPY;
  const loggedIn = !!token;
  const subscribe = loggedIn ? false : <SubscribeForm image={SUBSCRIBE_IMAGE} marketName={market.shortName} />;

  return (
    <Layout loggedIn={loggedIn} markets={markets} market={market} me={me}>
      <Head>
        <title key="title">{title}</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content="Outdoor Voices" />
        <meta name="apple-itunes-app" content={`app-id=${APP_ID}, app-argument=${url}`} />
        <meta name="twitter:site" content="@OutdoorVoices" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
      </Head>
      <Hero image={BANNER_IMAGE} market={market} />
      <div>
        <Filters
          clearFilters={onFilterClear}
          filtered={slug !== false}
          filters={[filter]}
          filterSelect={onFilterSelect}
          itemsLength={events.length}
        />
        <div className={cc([css.root, { [css.loggedIn]: loggedIn }])}>
          <div className={css.nav}>
            <div className={css.desktop}>{!loggedIn && subscribe}</div>
          </div>
          <div className={cc([css.lists, { [css.listsEmpty]: !any, [css.loggedIn]: loggedIn }])} id="main-content">
            {!any && <NoEvents {...emptyCopy} />}
            {hasThisWeek && <List title="This Week" items={linksThisWeek} />}
            {hasNextWeek && <List title="Next Week" items={linksNextWeek} />}
            {hasComingSoon && <List title="Coming Soon" items={linksComingSoon} />}
            <div className={cc([css.mobile, css.subscribeForm])}>{!loggedIn && any && subscribe}</div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

Markets.defaultProps = {
  slug: false,
  token: false,
  me: false,
};

Markets.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  markets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  me: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape()]),
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  rsvpMap: PropTypes.shape().isRequired,
  slug: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  token: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export async function getServerSideProps(context) {
  const { query } = context;
  const { serverAccessToken } = await serverSideAuthNoRedirect(context);
  const apolloClient = initializeApollo({ serverAccessToken });

  const { slug = false } = query;
  const today = moment().startOf('day').format('YYYY-MM-DD');
  let me = null;

  if (serverAccessToken) {
    try {
      const { data } = await apolloClient.query({
        query: ME_QUERY,
      });
      me = data.me;
    } catch (exc) {
      console.error(exc);
    }
  }

  const { data } = await apolloClient.query({
    query: GET_ALL_MARKETS_QUERY,
  });
  const markets = data.markets.edges.map(({ node }) => node);
  const response = await apolloClient.query({
    query: MARKETS_QUERY,
    variables: { today, marketSlug: !slug || slug === 'my-events' ? null : slug },
  });
  const rsvpsResponse = await apolloClient.query({ query: MY_RSVPS_QUERY, variables: { todayString: today } });
  const { rsvps } = rsvpsResponse.data;
  const { eventDates } = response.data;
  // Map the RSVPs to an object with the event date id as the key
  const rsvpMap = {};
  rsvps.edges.forEach(({ node }) => {
    const { eventDate, status } = node;
    rsvpMap[eventDate.id] = status;
  });

  return {
    props: {
      initialApolloState: apolloClient.cache.extract(),
      dates: eventDates.edges.map(({ node }) => node),
      markets,
      options: markets.length > 1 ? markets.map(({ slug: value, shortName: label }) => ({ label, value })) : [],
      rsvpMap,
      slug,
      me,
      token: serverAccessToken || false,
    },
  };
}

export default Markets;
