import PropTypes from 'prop-types';
import { useState } from 'react';

import Button from '../Button';
import Image from '../Image';
import css from './index.module.scss';
import objToParams from '../../utils/objToParams';

import { API_URL, DJANGO_HEADERS } from '../../config';

const SubscribeForm = ({ image, marketName, title }) => {
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const onChange = ({ target }) => {
    const newUser = {
      ...user,
      [target.name]: target.value,
    };
    setUser(newUser);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);

    const body = {
      ...user,
    };

    if (marketName) {
      body.market = marketName;
    }

    try {
      const subscribeFetch = await fetch(`${API_URL}subscribe/`, {
        ...DJANGO_HEADERS,
        body: objToParams(body),
        method: 'POST',
      });
      await subscribeFetch.json();
      setSubmitted(true);
      setSubmitting(false);
      setUser({
        firstName: '',
        lastName: '',
        email: '',
      });
    } catch (ex) {
      setSubmitting(false);
      setError(true);
    }
  };

  return (
    <section className={css.root}>
      <h2 className={css.title}>{title}</h2>
      <p className={css.copy}>Sign up to stay in the loop on all things Outdoor Voices</p>
      <form className={css.form} onSubmit={onSubmit}>
        <input
          aria-label="First Name"
          autoComplete="given-name"
          name="firstName"
          onChange={onChange}
          placeholder="First Name"
          required
          type="text"
          value={user.firstName}
        />
        <input
          aria-label="Last Name"
          name="lastName"
          onChange={onChange}
          placeholder="Last Name"
          required
          type="text"
          value={user.lastName}
        />
        <input
          aria-label="Enter Email"
          name="email"
          onChange={onChange}
          placeholder="Enter Email"
          required
          type="email"
          value={user.email}
        />
        {submitted && <p className={css.success}>You&rsquo;re signed up!</p>}
        {error && (
          <p className={css.error}>
            Sorry, we had a problem and can&rsquo;t sign you up right now. Please try again later
          </p>
        )}
        <Button
          disabled={submitting}
          aria-label="Sign up to get emailed about new events"
          className={css.submit}
          type="submit"
          white
        >
          {submitting ? '#DoingThings' : 'Subscribe'}
        </Button>
      </form>
      <p className={css.terms}>
        By signing up, you agree to our{' '}
        <a href="https://www.outdoorvoices.com/terms" rel="noopener noreferrer" target="_blank">
          Terms of Service
        </a>
      </p>
      {image && <Image {...image} className={css.image} />}
    </section>
  );
};

SubscribeForm.defaultProps = {
  image: false,
  marketName: false,
  title: 'Fear of Missing Out?',
};

SubscribeForm.propTypes = {
  image: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape()]),
  marketName: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  title: PropTypes.string,
};

export default SubscribeForm;
